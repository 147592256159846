.header {
    padding: 25px 0;
    text-align: center;
    background: #21313e;
}
.header img {
    width: 145px;
    height: 81px;
}
h1 {
    color: white;
}